<template>
  <div class="content">
    <div class="searchWrapper">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <div class="col_box">
          <div class="col_left">
            <el-form-item label="统计日期：">
              <a-date-picker
                ref="datePicker"
                :YearShowHidden="selectkeys[3]"
                :selectkeys="selectkeys"
                :isDefaultMonth="true"
              ></a-date-picker>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Parking_card_name')" prop="parkCardRuleId">
              <el-select
                filterable
                v-model="formInline.parkCardRuleId"
                placeholder="请选择"
                @change="parkCardRuleIdChange"
              >
                <el-option
                  :label="item.name"
                  :value="item.parkCardRuleId"
                  :key="item.parkCardRuleId"
                  v-for="item in ruleList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.park_name')">
              <my-component
                ref="parkInput"
                :areaIds="areaIds"
                :operationId="formInline.operationId"
                @valueChange="completeValue"
                slaveRelations="0,1"
              >
              </my-component>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button type="primary" icon="el-icon-search" @click="searchData">{{ $t('button.search') }}</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="table_header marginB20 graphShadow">
      <el-row :gutter="20">
        <el-col :span="4">
          <div class="grid-content">订单数量（个）</div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content">合计金额（元）</div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content">车主端订单数量（个）</div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content">车主端订单金额（元）</div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content">后台订单数量（个）</div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content">后台订单金额（元）</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class="grid-content" v-text="listByChannel.totalCount"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content" v-text="(listByChannel.totalMoney / 100).toFixed(2)"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content" v-text="listByChannel.appCount"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content" v-text="(listByChannel.appMoney / 100).toFixed(2)"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content" v-text="listByChannel.bossCount"></div>
        </el-col>
        <el-col :span="4">
          <div class="grid-content" v-text="(listByChannel.bossMoney / 100).toFixed(2)"></div>
        </el-col>
      </el-row>
    </div>
    <div class="countTable graphShadow" ref="countTable"></div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponentRoadside";
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "parkCarCountTable",
  data() {
    const start = new Date();
    const end = new Date(new Date().getTime() - 3600 * 1000 * 24);
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    return {
      listByChannel: {
        totalMoney: 0,
        totalCount: 0,
        appMoney: 0,
        appCount: 0,
        bossMoney: 0,
        bossCount: 0,
      },
      ruleList: [],
      formInline: {
        searchData: [dateFormat(start, "yyyy-MM-dd"), dateFormat(end, "yyyy-MM-dd")],
        name: "",
        operationId: "",
        parkId: "",
        parkName: "",
        parkCardRuleId: "",
      },
      selectkeys: ["month", "date", "week", "year"],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime() - 3600 * 1000 * 24;
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(new Date().getTime() - 3600 * 1000 * 24);
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(new Date().getTime() - 3600 * 1000 * 24);
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(new Date().getTime() - 3600 * 1000 * 24);
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      areaIds: "",
    };
  },
  components: {
    myComponent,
  },
  methods: {
    getListByChannel() {
      this.$axios
        .get("/acb/2.0/parkCardStat/statisticsByBuyChannel", {
          data: {
            type: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            parkId: this.formInline.parkId,
            parkCardRuleId: this.formInline.parkCardRuleId,

            // startDate: this.formInline.searchData[0],
            // endDate: this.formInline.searchData[1]
          },
        })
        .then((res) => {
          if (res.value.whole) {
            this.listByChannel = res.value.whole;
          } else {
            this.listByChannel = {
              appCount: 0,
              appMoney: 0,
              bossCount: 0,
              bossMoney: 0,
              totalCount: 0,
              totalMoney: 0,
            };
          }
          if (res.value.list) {
            let date = [];
            let total = [];
            let appTotal = [];
            let roomTotal = [];
            res.value.list.forEach((v) => {
              date.push(v.payDate); // 日期
              total.push(v.totalCount); // 全部渠道
              appTotal.push(v.appCount); // app购买
              roomTotal.push(v.bossCount); // boss录入
            });
            this.setCharta(date, total, appTotal, roomTotal);
          } else {
            this.setCharta();
          }
        })
        .catch((res) => {
          this.listByChannel = {
            appCount: 0,
            appMoney: 0,
            bossCount: 0,
            bossMoney: 0,
            totalCount: 0,
            totalMoney: 0,
          };
          this.setCharta();
          // console.log(res);
        });
    },
    setCharta(date = [0], total = [0], appTotal = [0], roomTotal = [0]) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.countTable);
      // let option = {
      //   color: ["#34b9f5", "#4ad2e7", "#ffce0a"],
      //   tooltip: {
      //     show: "true",
      //     trigger: "item",
      //     formatter: `类型：{a}<br/>
      // 						时间：{b}<br/>
      // 						数量：{c}
      // 			`
      //   },
      //   grid: {
      //     left: "3%",
      //     right: "5%",
      //     bottom: "11%",
      //     containLabel: true
      //   },
      //   legend: {
      //     data: ["数量合计", "车主端办理数量", "后台办理数量"],
      //     right: "5%"
      //   },
      //   xAxis: {
      //     show: true,
      //     type: "category",
      //     boundaryGap: false,
      //     name: "(日期)",
      //     data: date,
      //     axisLine: {
      //       show: false
      //     },
      //     splitLine: {
      //       show: false
      //     },
      //     axisTick: {
      //       show: false
      //     }
      //   },
      //   yAxis: {
      //     type: "value",
      //     name: "(数量)",
      //     show: true,
      //     position: "left",
      //     // axisLabel: {
      //     // 	formatter: function () {
      //     // 		return "";
      //     // 	}
      //     // },
      //     axisLine: {
      //       show: false
      //     },
      //     splitLine: {
      //       show: true,
      //       lineStyle: {
      //         type: "dashed"
      //       }
      //     },
      //     axisTick: {
      //       show: false
      //     }
      //   },
      //   series: [
      //     {
      //       name: "数量合计",
      //       type: "line",
      //       symbol: "circle",
      //       symbolSize: 6,
      //       data: total.length == 0 ? [0] : total
      //     },
      //     {
      //       name: "车主端办理数量",
      //       symbol: "circle",
      //       symbolSize: 6,
      //       type: "line",
      //       data: appTotal.length == 0 ? [0] : appTotal
      //     },
      //     {
      //       name: "后台办理数量",
      //       symbol: "circle",
      //       symbolSize: 6,
      //       type: "line",
      //       data: roomTotal.length == 0 ? [0] : roomTotal
      //     }
      //   ]
      // };
      let option = {
        color: ["#527BFF", "#94E0FF"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#e1e1e1",
            },
          },
        },
        legend: {
          type: "scroll",
          icon: "circle",
          itemWidth: 8,
          itemHeight: 8,
          bottom: 15,
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#E4ECF7",
              },
            },
            axisLabel: {
              color: "#B0B7C3",
            },
            data: date,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false,
            },
            // max: 40,
            splitNumber: 5,
            min: 0,
            // minInterval: 5,
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#B0B7C3",
            },
            splitLine: {
              type: "dotted",
              lineStyle: {
                type: "dashed",
                color: "#E4ECF7",
              },
            },
          },
        ],
        series: [
          {
            name: "车主端订单数量",
            type: "bar",
            stack: "Ad",
            barWidth: 16,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [2],
              },
            },
            data: appTotal.length == 0 ? [0] : appTotal,
          },
          {
            name: "后台订单数量",
            type: "bar",
            stack: "Ad",
            barWidth: 16,
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [2],
              },
            },
            data: roomTotal.length == 0 ? [0] : roomTotal,
          },
        ],
      };
      // 绘制图表
      myChart.setOption(option);
    },
    searchData() {
      this.getListByChannel();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    parkCardRuleIdChange() {
      let parksName = [];
      let parks = null;
      // this.formInline.numberRuleActivities.splice(1);
      this.ruleList.forEach((v) => {
        if (v.parkCardRuleId == this.formInline.parkCardRuleId) {
          if (v.scope == 1 && v.scopeDesc === "全部车场") {
            this.getScopes(v.operatorId);
          }
          parks = v.parks;
          this.formInline.validityPeriod = v.validityPeriod;
          // this.ruleStartTime = v.effectStartTime;
          this.scopeClick = v.scope;
          this.scopeDesc = v.scopeDesc;
          this.operationId = v.operationId;
          this.formInline.carMaxNum = v.carMaxNum;
          this.formInline.effectType = v.effectType;
          this.formInline.effectDaysAfterBuy = v.effectDaysAfterBuy;
          this.formInline.effectStartDate = this.$moment(parseInt(v.effectStartTime)).format(
            "YYYY-MM-DD"
          );
          this.formInline.effectEndDate = this.$moment(parseInt(v.effectEndTime)).format(
            "YYYY-MM-DD"
          );
        }
      });
      if (this.scopeClick == 2) {
        parks.forEach((v) => {
          parksName.push(v.parkName);
          this.formInline.useRangeDesc = parksName.toString();
        });
      } else {
        // 全部车场
        // parksName.push(this.scopeDesc);
        // this.getScopes(this.operatorId);
      }
    },
    // 获取全部车场名称
    getScopes(operationId) {
      const opt = {
        url: "/acb/2.0/park/currentUserParkList",
        method: "get",
        data: {
          operationId: operationId,
          dataSource: 0,
        },
        success: (res) => {
          let arr = [];
          res.value.forEach((item) => {
            arr.push(item.parkName);
          });
          this.formInline.useRangeDesc = arr.join("、");
        },
      };
      this.$request(opt);
    },
    // 获取停车卡名称列表
    getRulelist() {
      this.$axios
        .get("/acb/2.0/parkCardRule/list", {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          let ruleList = res && res.value.list;

          for (let i = 0; i < ruleList.length; i++) {
            if (ruleList[i].parkCardRuleId == this.$route.query.parkCardRuleId) {
            }
          }
          this.ruleList = ruleList;
        });
    },
  },
  mounted() {
    this.getListByChannel();
    this.getRulelist();
  },
  created() {},
  activated() {
    this.searchData();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.searchWrapper {
  overflow: hidden;
  padding: 22px 22px 0;
  margin-bottom: 20px;
}

.table_header {
  padding: 30px 60px;
  border: 1px solid #ddd;
}

.grid-content {
  text-align: center;
  line-height: 30px;
}

.countTable {
  padding-top: 60px;
  height: 350px;
}
</style>
